import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_ERROR,
    AUTH_CHECK,
    AUTH_GET_PERMISSIONS
} from 'react-admin';

const login_uri = process.env.REACT_APP_BACKEND_URL+'/api/login_check';
export default (type, params) => {
    switch (type) {
        case AUTH_LOGIN:
            const { email, password } = params;
            const request = new Request(`${login_uri}`, {
                method: 'POST',
                body: JSON.stringify({ email, password }),
                headers: new Headers({ 'Content-Type': 'application/json' }),
            });

            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300){
                        if(response.status === 401)
                            throw new Error("ra.auth.false_login");
                        if(response.status === 403)
                            throw new Error("ra.auth.inactive_account");
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(({token,data}) => {
                    if(data.role === 'customers')
                        throw new Error("ra.auth.access_unauthorized");
                    if(!data.active)
                        throw new Error("ra.auth.inactive_account");
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('token', token);
                    // localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('lang','de');
                    window.location.replace('/');
                });

        case AUTH_LOGOUT:
            localStorage.clear();
            return Promise.resolve();

        case AUTH_ERROR:

            if(params.response && params.response.status === 401 && params.response.statusText === "Unauthorized"){
                return Promise.reject({ message: params.message });
            }
            // return Promise.reject({ message: params.message });
            /*if(params.status <200 || params.status>= 300){
                if(params.message === "email: Email is already taken."){
                    return Promise.resolve(new Error('users.errors.emailExist'));
                }
                if(params.status === 403){
                    if(params.message === "onceLogged" || params.message === "disabledAccount"){
                        return Promise.reject();
                    }
                }
            }*/
            return Promise.resolve();

        case AUTH_CHECK:
            return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();

        case AUTH_GET_PERMISSIONS:
            const user = localStorage.getItem('user');
            const role = user ? JSON.parse(user).role: null;
            return role ? Promise.resolve(role) : Promise.reject();

        default:
            return Promise.resolve();
    }
}