import {
    useTranslate,
    Title
} from "react-admin";
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import {Redirect} from "react-router-dom";

const Dashboard =() => {
    const translate = useTranslate();
    return  (
        /*<Card>
            <Title title={translate('pos.welcome')} />
            <CardContent>Arntz Startseite</CardContent>
        </Card>*/
        <Redirect to="/admins" />
    );
};

export default Dashboard;
