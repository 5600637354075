import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,RichTextField
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

const CategoryTitle = ({ record }) => <span>{record.manufacturer}</span>;
// const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export  const CategoryCreate = props => {
    return (
        <Create {...props}
                title={<CategoryTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={requiredValidate}/>
            </SimpleForm>
        </Create>
    )
};

export  const CategoryEdit = props => {
    return (
        <Edit {...props}
                title={<CategoryTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={requiredValidate}/>
            </SimpleForm>
        </Edit>
    )
};

export const CategoryShow = props => {
    return(
        <Show {...props} title={<CategoryTitle/>} >
            <SimpleShowLayout >
                <TextField  source={"name"} addLabel={true} />
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn placeholder={translate('resources.categories.fields.name')}/>
        </Filter>
    )
};
export  const CategoriesList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField  source={"name"} addLabel={true} />
                {/*<RichTextField  source={"description"} addLabel={true} />*/}
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
