import englishMessages from 'ra-language-english';

const  users= {
    name: 'User |||| Users',
    fields: {
        address: "Address",
        gender: 'Gender',
        name:"Name",
        firstName:"First Name",
        genders:{
            male: 'Male',
            female: 'Female',
            other: 'Other',
        },
        notification:{
            emailPlatform:'Learning platform + Email',
            platform:'Learning platform',
        },
        notificationOption:'Notification options',
        confirm_password: 'Confirm Password',
        groups:'Groups',
        subscribers:"Subscribers",
        config: 'configuration',
        status: 'Status'
    },
    fieldGroups: {
        identity: 'Identity',
        address: 'Address',
        group: 'Group',
        password: 'Password',
        change_password: 'Change Password',
        status: 'Active'
    },
    page: {
        delete: 'Delete Customer',
    },
    errors: {
        password_mismatch:'The password confirmation is not the same as the password.',
        zip:'Must be a valid zip code',
        phone:'Must be a valid phone',
        emailExist: 'This Email is already used',
        password_length:'Password must be 8 characters',
        password_confirm_length:'Password confirmation must be 8 characters',
        token:'Token is expired',
        required:'please enter the field'
    },
    weekDays:{
        mo :'Mo.',
        tu:'Tu.',
        we:'We.',
        th:'Th.',
        fr:'Fr.',
        sa:'Sa.',
        su:'Su.'
    }
};

export default {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Parameter',
        profile: 'My Profile',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            name: "Dashboard"
        },
        menu: {
            users: 'Profile',
            menuCourseOrganisation: 'Course-Organisation',
            customers: 'Customers',
            courseManagements: 'CourseManagement',
            menuInvoice: 'Invoices',
            menuOrganisation: 'Organisation',
            menuUsers: 'Users',
        },
        delete:{
            name : 'Delete',
            msg : 'Are you sure you want to delete these?',
            success: 'has been Successfully deleted',
            successMsg:'Subscribers have been successfully added'
        },
        forgetPassword:{
            successMsg:'Email has been sent',
            link:'Forget password?'
        },
        resetPassword:{
            successMsg:'Password has been successfully changed'
        },
        participant:{
            manualAdd:'Add Subscriber',
            add: 'Add individual participants',
            import: 'Import via CSV file',
            successMsg:'Subscriber was added successfully'
        },
        months:{
            jan :'January',
            feb:'February',
            mar:'March',
            apr:'April',
            may:'May',
            jun:'June',
            jul:'July',
            aug:'August',
            sep:'September',
            oct:'October',
            nov:'November',
            dec:'December',
        },
        weekDays:{
            mo :'Mo.',
            tu:'Tu.',
            we:'We.',
            th:'Th.',
            fr:'Fr.',
            sa:'Sa.',
            su:'Su.'
        },
        month:'Month',
        year:'Year',
        searchBtn : 'Search',
        noResult: 'No results found',
        startMeeting:'Start meeting',
        welcome:'Welcome to the Administration',
        PageNotFound:' Page not found',
    },
    resources: {
        companies: {
            name: 'Companies |||| Companies',
            fields: {
                name :"Name",
                number :"Number",
                ...users.fields
            },
        },
        products: {
            name: 'Products |||| Products',
            fields: {
                name :"Product number",
                description :"Description",
                ean :"EAN",
                pageNumberDe :"Page number in the German catalog",
                pageNumberEn :"Page number in the English catalog",
            },
        },
        stocks: {
            name: 'Stocks |||| Stocks',
            fields: {
                stock :"Inventory",
                minStock :"Reorder point",
                storage :"Storage name",
                minOrder :"Purchasing volume",
                product :"Product",
            },
        },
        storages: {
            name: 'Storages |||| Storages',
            fields: {
                name :"Name",
                location: "Location",
                company: "Company",
                user:"User"
            },
        },
        notifications: {
            name: 'Notifications |||| Notifications',
            fields: {
                message :"Message",
                user: "Admin",
                storage: "Storage",
                subject: "subject",
            },
        },
        dimensions: {
            name: 'Dimension |||| Dimensions',
            fields: {
                name :"Dimensions",
                height: "Width of blade (mm)",
                strength: "Thickness of blade (mm)",
            },
        },
        gearings: {
            name: 'Gearing |||| Gearings',
            fields: {
                name :"Name",
                description: "Description",
            },
        },
        saws: {
            name: 'Saw |||| Saws',
            fields: {
                manufacturer :"Sawing machine producer",
            },
        },
        models: {
            name: 'Model |||| Model',
            fields: {
                name :"Sawing machine type",
                length :"Length of blade (mm)",
                factor :"Factor",
            },
        },
        specifications: {
            name: 'Specification |||| Specifications',
            fields: {
                name :"Name",
                type :"type",
                height :"type",
                width :"width",
                sideLength :"sideLength",
                diameter :"diameter",
                GType :"GType",
                outerDiameterFrom :"outerDiameter From",
                outerDiameterTo :"outerDiameter To",
                wallThicknessFrom :"wallThickness From",
                wallThicknessTo :"wallThickness To",
                thicknessFrom :"thickness From",
                thicknessTo :"thickness To",
            },
        },
        materialGroups: {
            name: 'materialGroup |||| materialGroups',
            fields: {
                name :"Name",
            },
        },
        speeds: {
            name: 'Speed |||| Speeds',
            fields: {
                feed :"Feed",
                speed :"Speed",
                gearing :"Gearing name",
                category :"Category name",
                materialGroup :"MaterialGroup name",
                specification :"Specification",
                dimension :"Dimension",
            },
        },
        tpms: {
            name: 'Teeth |||| Teeths',
            fields: {
                tpm :"Teeth",
                gearing :"Gearing name",
                category :"Category name",
            },
        },
        emailTemplates: {
            name: 'EmailTemplate |||| EmailTemplate',
            fields: {
                name :"Name",
                subject :"Subject",
                description :"Content",
                vars :"Fields",
            },
        },
        materials: {
            name: 'Material |||| Materialien',
            fields: {
                name :"Name",
                number :"Material number",
                din :"Material according to DIN",
            },
        },
        decisions: {
            name: 'Decisions |||| Decisions',
            fields: {
                name :"Name",
            },
        },

        users:{...users},
        admins:{
            name: 'Admins |||| Admins',
            fields: {...users.fields},
        },
        customers: {
            name: 'Customers |||| Customers',
            moduleName: 'Customer',
            fields: {label:'Customer',company:'company',...users.fields},
            fieldGroups: {...users.fieldGroups},
            page:{...users.page},
            errors:{...users.errors},
            tabs:{
                infoPersonnel:'Information Personnel',
                bookings:'Bookings',
                courses:'Courses',
            }

        },
        tests: {
            name: 'Test |||| Tests',
            moduleName: 'Test',
            fields: {
                name: "Name",
                articulateTestId: "Articulate Test Id",
                onlineCourse: "Course"
            },
        },
        course_templates: {
            name: 'Course template |||| Course templates ',
            moduleName: 'Course template',
            fields: {
                courseName: "Course name",
                templateName: "Template name",
                timeOf: "Time of",
                timeTo: "Time to",
                weekDays: "Week days",
                courseType: "Course type",
            },
        },
        ticket_templates: {
            name: 'Ticket template |||| Tickets templates',
            moduleName: 'Ticket template',
            fields: {
                name: "Name",
                duration: "Duration in (weeks)",
                price: "Price",
                courseType: "Course type"
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                name :"Name",
                courseTypes: "CourseTypes"
            },
        },
        course_types: {
            name: 'CourseType |||| CourseTypes',
            moduleName: 'CourseType',
            fields: {
                category: "category",
                name : "Name",
                description: "Description"
            },
        },
        tickets: {
            name: 'Ticket |||| Tickets',
            fields: {
                name: "Name",
            },
        },
        online_course_bookings: {
            name: 'Invoice |||| Invoices',
            moduleName: 'Invoices',
            fields: {
                customer : "customer",
                customerName : "customerName",
                course : "course",
                courseName : "courseName",
                payment: "payment",
                paymentStatus: "paymentStatus",
                bookingStatus : "bookingStatus",
                invoiceNbr: "invoiceNbr.",
                createdAt: "createdAt",
                updatedAt: "updatedAt",
                blockAccess:"blockAccess",
                enableAccess:"enableAccess",

                accessUntil:'accessUntil'
            },
            courseChange : 'Change Course',
            invoiceBtn:'Invoice',
            invoiceStornoBtn:'Storno Invoice',
        },
    },

    ra: {
        ...englishMessages.ra,
        page :{
            ...englishMessages.ra.page,
            loading: "Loading",
            invite : "Invite",
            empty : "Empty"
        },
        auth:{
            ...englishMessages.ra.auth,
            forget_password: 'Forget Password',
            reset_password: 'Reset Password',
            false_login : 'Username or Password is incorrect',
            inactive_account : 'Your Account is Disabled',
            access_unauthorized: 'Access unauthorized',
            not_found_email : 'E-Mail not found',
            email_validate : 'Must be a valid email',
            auth_check_error:'Please login to continue',
        }

    }
};
