import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';

import {DateField, EditButton, EmailField, useTranslate, BooleanField, FunctionField,TextField} from 'react-admin';

import AvatarField from './AvatarField';
import AddressField from "./AddressField";

const useStyles = makeStyles(theme => ({
    root: { margin: '1em' },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '0.5rem 0',
    },
    cardTitleContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cardContent: {
        ...theme.typography.body1,
        display: 'flex',
        flexDirection: 'column',
    },
}));

function genderConvert(record ) {
    if(record.gender === 0)
        return 'Männlich';//'resources.users.fields.genders.male';
    if(record.gender === 1)
        return 'Weiblich';//'resources.users.fields.genders.female';
    if(record.gender === 2)
        return  'Andere'; //'resources.users.fields.genders.other';

}

const MobileGrid = ({ ids, data, basePath }) => {
    const translate = useTranslate();
    const classes = useStyles();

    if (!ids || !data) {
        return null;
    }

    return (
        <div className={classes.root}>
            {ids.map(id => (
                <Card key={id} className={classes.card}>
                    <CardHeader
                        title={
                            <div className={classes.cardTitleContent}>
                                <h2>{`${data[id].firstName} ${
                                    data[id].lastName
                                }`}</h2>
                                <EditButton
                                    resource="users"
                                    basePath={basePath}
                                    record={data[id]}
                                />
                            </div>
                        }
                        avatar={<AvatarField record={data[id]} size="45" />}
                    />
                    <CardContent className={classes.cardContent}>
                        <div>
                            {translate(
                                'resources.users.fields.address'
                            )}
                            &nbsp;:&nbsp;
                            <AddressField
                                record={data[id]}
                                label="address"
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.users.fields.phone'
                            )}
                            &nbsp;:&nbsp;
                            <TextField record={data[id]} source="phone" type="phone" label="phone" />
                        </div>
                        <div>
                            {translate(
                                'resources.users.fields.email'
                            )}
                            &nbsp;:&nbsp;
                            <EmailField
                                record={data[id]}
                                source="email"
                                label="email"
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.users.fields.birthday'
                            )}
                            &nbsp;:&nbsp;
                            <DateField
                                record={data[id]}
                                source="birthday"
                                label="birthday"
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.users.fields.active'
                            )}
                            &nbsp;:&nbsp;
                            <BooleanField
                                record={data[id]}
                                source="active"
                                resource="users"
                                label="active"
                            />
                        </div>
                        <div>
                            {translate(
                                'resources.users.fields.gender'
                            )}
                            &nbsp;:&nbsp;
                            <FunctionField  resource="users"  label='resources.users.fields.gender' render={genderConvert} />
                        </div>

                    </CardContent>
                </Card>
            ))}
        </div>
    );
};

MobileGrid.defaultProps = {
    data: {},
    ids: [],
};

export default MobileGrid;
