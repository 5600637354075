import {
    DateInput,
    Create,
    BooleanInput,
    TextInput,
    Toolbar,
    useTranslate,
    SelectInput, usePermissions, SaveButton, SimpleForm,
    required,
    email,
    minLength,
    maxLength,
    regex,ReferenceInput,
    useNotify
} from 'react-admin';

import { Box, Typography } from '@material-ui/core';


const CreateActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const validateStreet = [required(), minLength(2), maxLength(30)];

const UserCreate = (props) => {
    const translate = useTranslate();
    const { permissions } = usePermissions();
    const requiredValidate = [required()];
    const validateEmail = [required(),email()];
    const validateZipCode = [required(),regex(/^\d+$/,minLength(2),maxLength(15), translate('resources.users.errors.zip'))];
    const validateFirstName = [required(), minLength(2), maxLength(100)];
    const validateCity = [required(), minLength(2), maxLength(15)];
    const phoneValidate = [required(),regex(/\(?\+\(?49\)?[ ()]?([- ()]?\d[- ()]?){10}/im,translate('resources.users.errors.phone'))];
    const url = (window.location.hash.split('/')[3]);
    // const redirectLink = url === 'booking' ? "/bookings/create" : "list";
    const notify = useNotify();
    return (
        <Create
            {...props}
            title={"pos.user.create"}
            onFailure={(error) => notify( 'E-Mail ist bereits vergeben', 'warning')}
        >
            <SimpleForm toolbar={<CreateActions />} >
                <Box style={{width:'100%'}} display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.users.fieldGroups.identity'
                                    )}
                                </Typography>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box
                                        flex={1}
                                        mr={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <TextInput
                                            source="firstName"
                                            resource="users"
                                            validate={validateFirstName}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box
                                        flex={1}
                                        ml={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <TextInput
                                            source="lastName"
                                            resource="users"
                                            validate={validateFirstName}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                                <TextInput
                                    type="email"
                                    source="email"
                                    resource="customers"
                                    validate={validateEmail}
                                    fullWidth
                                />
                                {/*<Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box
                                        flex={1}
                                        mr={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <DateInput
                                            source="birthday"
                                            validate={requiredValidate}
                                            resource="users"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box
                                        flex={1}
                                        ml={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <SelectInput source="gender" resource="users" fullWidth
                                                     validate={requiredValidate}
                                                     choices={[
                                            { id: 0, name: 'resources.users.fields.genders.male'},
                                            { id: 1, name: 'resources.users.fields.genders.female' },
                                            { id: 2, name: 'resources.users.fields.genders.other' },
                                        ]} />
                                    </Box>
                                </Box>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <TextInput type="tel" source="phone" validate={phoneValidate} resource="users" fullWidth/>
                                </Box>
                                <Box mt="1em" />

                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.users.fieldGroups.address')}
                                </Typography>
                                <TextInput
                                    validate={validateStreet}
                                    source="street"
                                    resource="users"
                                    fullWidth
                                />
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box
                                        flex={1}
                                        mr={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <TextInput
                                            validate={validateZipCode}
                                            source="zip"
                                            resource="users"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <TextInput
                                            validate={validateCity}
                                            source="city"
                                            resource="users"
                                            fullWidth
                                        />
                                    </Box>
                                </Box>

                                <Box mt="1em" />*/}
                            </Box>
                            <Box
                                flex={1}
                                ml={{ xs: 0, lg: '1em' }}
                                mt={{ xs: '1em', lg: 0 }}
                            >
                                    { props.resource === 'customers' && (
                                        <div>
                                            <Typography variant="h6" gutterBottom>
                                                 {translate(
                                                    'resources.customers.fields.company'
                                                )}
                                            </Typography>
                                            <div>
                                                <ReferenceInput
                                                             source="company"
                                                             reference="companies"
                                                             label={translate('resources.customers.fields.company')}
                                                >
                                                    <SelectInput  validate={requiredValidate} optionText="name" />
                                                </ReferenceInput>
                                            </div>
                                        </div>
                                    )}
                                <div>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fields.config'
                                        )}
                                    </Typography>
                                    <div>
                                        <BooleanInput
                                            defaultValue={true}
                                            source="masterCalc"
                                            resource="users"
                                        />
                                    </div>
                                    <div>
                                        <BooleanInput
                                            defaultValue={false}
                                            source="konsignationsLager"
                                            resource="users"
                                        />
                                    </div>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fields.status'
                                        )}
                                    </Typography>
                                    <div>
                                        <BooleanInput
                                            defaultValue={true}
                                            source="active"
                                            resource="users"
                                        />
                                    </div>
                                </div>
                            </Box>
                        </Box>
            </SimpleForm>
        </Create>
    );
};





export default UserCreate;
