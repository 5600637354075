import { useState} from 'react';
import { useSelector } from 'react-redux';
import {useTranslate, DashboardMenuItem, MenuItemLink} from 'react-admin';

import users from '../components/users';
import company from '../components/company';
import product from '../components/product';
import storage from '../components/storage';
import stock from '../components/stock';
import notification from '../components/notification';
import dimension from '../components/dimension';
import gearing from '../components/gearing';
import saw from '../components/saw';
import model from '../components/model';
import category from '../components/category';
import specification from '../components/specification';
import materialGroup from '../components/materialGroup';
import material from '../components/material';
import decision from '../components/decision';
import speed from '../components/speed';
import tpm from '../components/tpm';
import emailTemplate from '../components/emailTemplate';
import SubMenu from './SubMenu';

const Menu= ({onMenuClick, dense }) => {
    const [state, setState] = useState({
        menuUsers: true,
        menuCourseOrganisation: true,
        menuCourseManagement: true,
        menuTemplate: true,
        menuOrganisation: true,
        menuCustomers: true,
        menuInvoices: true,
    });
    const translate = useTranslate();
    /*const isXSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('xs')
    );*/
    const open = useSelector((state) => state.admin.ui.sidebarOpen);
    useSelector((state) => state.theme);

    const handleToggle = (menu) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div>
            {' '}
            {/*<DashboardMenuItem onClick={onMenuClick}*/}
            {/*                   sidebarIsOpen={open}*/}
            {/*                   primaryText={translate(`pos.dashboard.name`, {smart_count: 2,})}/>*/}
            <SubMenu
                handleToggle={() => handleToggle('menuUsers')}
                isOpen={state.menuUsers}
                sidebarIsOpen={open}
                name="pos.menu.menuUsers"
                icon={<users.icon/>}
                dense={dense}
            >
                <MenuItemLink
                    to={`/admins`}
                    primaryText={translate(`resources.admins.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
                <MenuItemLink
                    to={`/customers`}
                    primaryText={translate(`resources.customers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon/>}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
            </SubMenu>
            <MenuItemLink
                to={`/companies`}
                primaryText={translate(`resources.companies.name`, {
                    smart_count: 2,
                })}
                leftIcon={<company.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/products`}
                primaryText={translate(`resources.products.name`, {
                    smart_count: 2,
                })}
                leftIcon={<product.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/storages`}
                primaryText={translate(`resources.storages.name`, {
                    smart_count: 2,
                })}
                leftIcon={<storage.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/stocks`}
                primaryText={translate(`resources.stocks.name`, {
                    smart_count: 2,
                })}
                leftIcon={<stock.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/notifications`}
                primaryText={translate(`resources.notifications.name`, {
                    smart_count: 2,
                })}
                leftIcon={<notification.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/dimensions`}
                primaryText={translate(`resources.dimensions.name`, {
                    smart_count: 2,
                })}
                leftIcon={<dimension.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/gearings`}
                primaryText={translate(`resources.gearings.name`, {
                    smart_count: 2,
                })}
                leftIcon={<gearing.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/saws`}
                primaryText={translate(`resources.saws.name`, {
                    smart_count: 2,
                })}
                leftIcon={<saw.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/models`}
                primaryText={translate(`resources.models.name`, {
                    smart_count: 2,
                })}
                leftIcon={<model.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/categories`}
                primaryText={translate(`resources.categories.name`, {
                    smart_count: 2,
                })}
                leftIcon={<category.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/specifications`}
                primaryText={translate(`resources.specifications.name`, {
                    smart_count: 2,
                })}
                leftIcon={<specification.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/material_groups`}
                primaryText={translate(`resources.materialGroups.name`, {
                    smart_count: 2,
                })}
                leftIcon={<materialGroup.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/materials`}
                primaryText={translate(`resources.materials.name`, {
                    smart_count: 2,
                })}
                leftIcon={<material.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/decisions`}
                primaryText={translate(`resources.decisions.name`, {
                    smart_count: 2,
                })}
                leftIcon={<decision.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/speeds`}
                primaryText={translate(`resources.speeds.name`, {
                    smart_count: 2,
                })}
                leftIcon={<speed.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/tpms`}
                primaryText={translate(`resources.tpms.name`, {
                    smart_count: 2,
                })}
                leftIcon={<tpm.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
            <MenuItemLink
                to={`/email_templates`}
                primaryText={translate(`resources.email_templates.name`, {
                    smart_count: 2,
                })}
                leftIcon={<emailTemplate.icon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
            />
        </div>
    );
};

export default Menu;
