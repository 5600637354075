import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {Title, useTranslate} from 'react-admin';

export default () => {
    const translate = useTranslate();
    return (
        <Card>
            <Title title={translate('pos.PageNotFound')} />
            <CardContent>
                <h1>404:{translate('pos.PageNotFound')}</h1>
            </CardContent>
        </Card>
    )
};