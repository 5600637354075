import germanMessages from 'ra-language-german';
import {NumberInput, SimpleForm, TextInput} from "react-admin";
const  users= {
    name: 'Benutzer |||| Benutzer',
    fields: {
        name:"Name",
        email:'Email',
        firstName: 'Vorname',
        lastName: 'Nachname',
        gender: 'Geschlecht',
        birthday: 'Geburtstag',
        street: 'Straße, Hausnummer',
        zip: 'PLZ',
        city: 'Ort',
        phone: 'Telefonnummer',
        avatar: 'Bild',
        groups: 'Gruppe',
        active: 'Active',
        genders:{
            male: 'Männlich',
            female: 'Weiblich',
            other: 'Andere',
        },
        address: "Adresse",
        plainPassword: "Passwort",
        confirm_password: 'Kennwort bestätigen',
        notification:{
            emailPlatform:'Lernplattform + Email',
            platform:'Lernplattform',
        },
        notificationOption:'Benachrichtigungsoptionen',
        subscribers:"Teilnehmer",
        config: 'Konfiguration',
        status: 'Status'

    },
    fieldGroups: {
        identity: 'Identität',
        address: 'Adresse',
        group: 'Gruppe',
        zip: 'Plz',
        city: 'Stadt',
        phone: 'Telefonnummer',
        avatar: 'Bild',
        password: 'Passwort',
        change_password: 'Passwort ändern',
        status: "Status"
    },
    page: {
        delete: 'Delete Users',
    },
    errors: {
        password_mismatch:'Die Passwortbestätigung stimmt nicht mit dem Passwort überein.',
        zip:'Muss eine gültige Postleitzahl sein',
        phone:'Muss eine gültige Telefonnummer sein',
        emailExist: 'Diese E-Mail ist bereits verwendet',
        password_length:'Passwort muss 8 Character sein',
        password_confirm_length:'Passwortbestätigung muss 8 Character sein',
        token:'Token ist abgelaufen',
        required:'bitte das Feld eingeben'
    }
};

export default {
    ...germanMessages,
    pos: {
        search: 'Suchen',
        configuration: 'Einstellungen',
        profile: 'Mein Profil',
        language: 'Sprache',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            name: "Startseite"
        },
        menu: {
            users: 'Profile',
            menuUsers: 'BENUTZER',
            menuCourseOrganisation: 'Kurs-Organisation',
            courseManagements: 'KURSVERWALTUNG',
            menuTemplate: 'Vorlagen',
            menuOrganisation: 'ORGANISATION',
            customers: 'KUNDEN',
            menuInvoice: 'FAKTURA',
        },
        participant:{
            manualAdd:'Teilnehmer hinzufügen',
            add: 'Einzelnen Teilnehmer hinzufügen',
            import: 'Import über CSV-Datei',
            successMsg:'Teilnehmer wurde erfolgreich hinzufügt'
        },
        delete:{
            name : 'Löschen',
            msg : 'Möchten Sie diese wirklich löschen?',
            success: 'wurde erfolgreich gelöscht'
        },
        disable:{
            name : 'deaktivieren',
            msg : 'Möchten Sie diese wirklich deaktivieren?',
            success: 'wurde erfolgreich deaktiviert',
            error: ''
        },
        forgetPassword:{
            successMsg:'Email wurde geschickt',
            link:'Passwort vergessen?'
        },
        resetPassword:{
            successMsg:'Passwort wurde erfolgreich geändert'
        },
        user:{
            create :"Benutzer Erstellen"
        },
        weekDays:{
            mo :'Mo.',
            tu:'Di.',
            we:'Mi.',
            th:'Do.',
            fr:'Fr.',
            sa:'Sa.',
            su:'So.'
        },
        months:{
            jan :'Januar',
            feb:'Februar',
            mar:'Märs',
            apr:'April',
            may:'May',
            jun:'Juni',
            jul:'Juli',
            aug:'August',
            sep:'September',
            oct:'Oktober',
            nov:'November',
            dec:'Dezember',
        },
        month:'Monat',
        year:'Jahr',
        searchBtn : 'Suchen',
        noResult:'Keine Resultate gefunden',
        startMeeting:'Meeting starten',
        welcome:'Willkommen in der Administration',
        PageNotFound:' Seite nicht gefunden',
    },
    resources: {
        companies: {
            name: 'Firmen |||| Firmen',
            fields: {
                name :"Name",
                number :"Nummer",
                ...users.fields
            },
        },
        products: {
            name: 'Produkte |||| Produkte',
            fields: {
                name :"Artikelnummer",
                description :"Beschreibung",
                ean :"EAN",
                pageNumberDe :"Seitennummer im deutschen Katalog",
                pageNumberEn :"Seitennummer im englischen Katalog",
            },
        },
        stocks: {
            name: 'Bestände |||| Bestände',
            fields: {
                stock :"Bestand",
                minStock :"Meldebestand",
                storage :"Lagername",
                minOrder :"Beschaffungsmenge",
                product :"Produkt",
            },
        },
        storages: {
            name: 'Lager |||| Lager',
            fields: {
                name :"Name",
                location: "Standort",
                company: "Firma",
                user:"Benutzer"
            },
        },
        notifications: {
            name: 'Benachrichtigungen |||| Benachrichtigungen',
            fields: {
                message :"Nachricht",
                user: "Admin",
                storage: "Lager",
                subject: "Betreff",
            },
        },
        dimensions: {
            name: 'Abmessungen |||| Abmessungen',
            fields: {
                name :"Abmessungen",
                height: "Sägeband-Breite (mm)",
                strength: "Sägeband-Dicke (mm)",
            },
        },
        gearings: {
            name: 'Verzahnung |||| Verzahnungen',
            fields: {
                name :"Name",
                description: "Beschreibung",
            },
        },
        saws: {
            name: 'Hersteller |||| Hersteller',
            fields: {
                manufacturer :"Sägemaschinen-Hersteller",
            },
        },
        models: {
            name: 'Sägemaschinentyp |||| Sägemaschinentyp',
            fields: {
                name :"Sägemaschinentyp",
                length :"Sägeband-Länge (mm)",
                factor :"Faktor",
            },
        },
        speeds: {
            name: 'Geschwindigkeit |||| Geschwindigkeit',
            fields: {
                feed :"Feed",
                speed :"Speed",
                gearing :"Verzahnungsname",
                category :"Kategoriename",
                materialGroup :"MaterialGruppename",
                specification :"Spezifikation",
                dimension :"Maße",
            },
        },
        tpms: {
            name: 'Zahn |||| Zähne',
            fields: {
                tpm :"Zahn",
                gearing :"Verzahnungsname",
                category :"Kategoriename",
            },
        },
        email_templates: {
            name: 'Email Vorlage |||| Email Vorlage',
            fields: {
                name :"Name",
                subject :"Betreff",
                description :"Inhalt",
                vars :"Felder",
            },
        },
        specifications: {
            name: 'Spezifikation |||| Spezifikationen',
            fields: {
                name :"Name",
                type :"Typ",
                height :"Höhe",
                width :"Breite",
                sideLength :"seitenläge",
                diameter :"Durchmesser",
                GType :"GType",
                outerDiameterFrom :"Außendurchmesser von",
                outerDiameterTo :"Außendurchmesser bis",
                wallThicknessFrom :"Wandstärke von",
                wallThicknessTo :"Wandstärke bis",
                thicknessFrom :"Dicke von",
                thicknessTo :"Dicke bis",
            },
        },
        materialGroups: {
            name: 'Materialgruppe |||| Materialgruppen',
            fields: {
                name :"Name",
            },
        },
        materials: {
            name: 'Material |||| Materialien',
            fields: {
                name :"Name",
                number :"Werkstoffnummer",
                din :"Werkstoff nach DIN",
            },
        },
        decisions: {
            name: 'Entscheidung |||| Entscheidungen',
            fields: {
                name :"Name",
            },
        },
        users:{...users},
        admins:{
            name: 'Admin |||| Admin',
            fields: {...users.fields},
        },
        customers: {
            name: 'Kunde |||| Kunden',
            moduleName: 'Kunde',
            fields: {label:'Kunde',company:'Firma',...users.fields},
            fieldGroups: {...users.fieldGroups},
            page:{...users.page},
            errors:{...users.errors},
            tabs:{
                infoPersonnel:'Persönliche information',
                bookings:'Buchungen',
                courses:'Kurse',
            }

        },
        categories: {
            name: 'Kategorie |||| Kategorien',
            fields: {
                name :"Name",
                courseTypes: "Kursarten",
            },
        },
        course_types: {
            name: 'Kursart |||| Kursarten',
            moduleName: 'Kursart',
            fields: {
                category: "Kategorie",
                name : "Name",
                description: "Beschreibung"
            },
        },
        tickets: {
            name: 'Ticket |||| Tickets',
            fields: {
                name: "Name",
            },
        },
        course_templates: {
            name: 'Vorlage Kurs |||| Vorlagen Kurse ',
            moduleName: 'Vorlage Kurs',
            fields: {
                courseName: "Kursname",
                templateName: "Vorlagename",
                timeOf: "Uhrzeit von",
                timeTo: "Uhrzeit bis",
                weekDays: "WochenTage",
                courseType: "Kursart",
            },
        },
        ticket_templates: {
            name: 'Vorlage Ticket |||| Vorlagen Tickets',
            moduleName: 'Vorlage Ticket',
            fields: {
                name: "Name",
                duration: "Dauer in (Wochen)",
                price: "Preis",
                courseType: "Kursart"
            },
        },
        tests: {
            name: 'Test |||| Teste',
            moduleName: 'Test',
            fields: {
                name: "Name",
                articulateTestId: "Articulate Test Id",
                onlineCourse: "Kurse"
            },
        },
        permissions: {
            name: 'Berechtigung |||| Berechtigungen',
            moduleName: 'Berechtigung',
            fields: {
                permission: 'Berechtigung',
                groups: "Gruppen",
                module: "Module",
                name: "Name",
            },
            type:{
                create:"Erstellen",
                read:"Lesen",
                edit:"Ändern",
                delete:"Löschen",
            }
        },
        backend_groups: {
            name: 'Gruppe |||| Gruppen',
            moduleName: 'Gruppe',
            fields: {
                name:'Name',
                permissions: 'Berechtigungen',
                users: "Mitglieder",
                role:'Rolle',
                roles:{
                    admin:'Admin',
                    teacher : 'Lehrkraft',
                    customer : 'Kunde'
                }
            },
            errors:{
                notDefaultGroupDeleted :'Kann leider nicht entfernt werden (es ist eine Standardgruppe)'
            }
        },
    },

    ra: {
        ...germanMessages.ra,
        page :{
            ...germanMessages.ra.page,
            loading: "Wird geladen",
            invite : "Einladen",
            empty : "Leer"
        },
        input:{
            ...germanMessages.ra.input,
          password: {
              ...germanMessages.ra.input.password,
              toggle_hidden: "toggle_hidden",
              toggle_visible: "toggle_visible"
          }
        },
        boolean:{
            ...germanMessages.ra.boolean,
            null:""
        },
        notification:{
            ...germanMessages.ra.notification,
            logged_out: "Abmelden"
        },
        auth:{
            ...germanMessages.ra.auth,
            forget_password: 'Passwort zurücksetzen',
            reset_password: 'Passwort ändern',
            false_login : 'Benutzername oder Passwort ist falsch',
            inactive_account : 'Ihr Konto ist deaktiviert',
            access_unauthorized : 'Zugriff nicht autorisiert',
            not_found_email : 'E-Mail nicht gefunden',
            email_validate : 'Muss eine gültige E-Mail sein',
            auth_check_error:'Bitte einloggen zum Fortfahren',
            email:'Email'
        },
        action:{
            ...germanMessages.ra.action,
            unselect: 'Auswählen'
        }
    }
};
