import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,ReferenceInput,SelectInput,
    ReferenceField,NumberField,NumberInput
} from 'react-admin';

const DimensionTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export  const DimensionCreate = props => {
    return (
        <Create {...props}
                title={<DimensionTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={requiredValidate}/>
                <NumberInput source={"height"} validate={requiredValidate}/>
                <NumberInput source={"strength"} validate={requiredValidate}/>
            </SimpleForm>
        </Create>
    )
};

export  const DimensionEdit = props => {
    return (
        <Edit {...props}
                title={<DimensionTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={requiredValidate}/>
                <NumberInput source={"height"} validate={requiredValidate}/>
                <NumberInput source={"strength"} validate={requiredValidate}/>
            </SimpleForm>
        </Edit>
    )
};

export const DimensionShow = props => {
    return(
        <Show {...props} title={<DimensionTitle/>} >
            <SimpleShowLayout >
                <TextField  source={"name"} addLabel={true} />
                <NumberField  source={"height"} addLabel={true} />
                <NumberField  source={"strength"} addLabel={true} />
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn placeholder={translate('resources.dimensions.fields.name')}/>
            <NumberInput source={"height"} />
            <NumberInput source={"strength"} />
        </Filter>
    )
};
export  const DimensionsList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField  source={"name"} addLabel={true} />
                <NumberField  source={"height"} addLabel={true} />
                <NumberField  source={"strength"} addLabel={true} />
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
