import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,RichTextField
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

const SawTitle = ({ record }) => <span>{record.manufacturer}</span>;
// const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export  const SawCreate = props => {
    return (
        <Create {...props}
                title={<SawTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"manufacturer"} validate={requiredValidate}/>
                <RichTextInput source={"description"}/>
            </SimpleForm>
        </Create>
    )
};

export  const SawEdit = props => {
    return (
        <Edit {...props}
                title={<SawTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"manufacturer"} validate={requiredValidate}/>
                <RichTextInput source={"description"}/>
            </SimpleForm>
        </Edit>
    )
};

export const SawShow = props => {
    return(
        <Show {...props} title={<SawTitle/>} >
            <SimpleShowLayout >
                <TextField  source={"manufacturer"} addLabel={true} />
                <RichTextField  source={"description"} addLabel={true} />
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="manufacturer" alwaysOn placeholder={translate('resources.saws.fields.manufacturer')}/>
        </Filter>
    )
};
export  const SawsList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField  source={"manufacturer"} addLabel={true} />
                {/*<RichTextField  source={"description"} addLabel={true} />*/}
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
