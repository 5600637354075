import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,
    SelectInput,NumberInput,NumberField
} from 'react-admin';

const SpecificationTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const typeChoices = [
    { id: 'Rohr', name: 'Rohr' },
    // { id: 'Quadrat', name: 'Quadrat' },
    // { id: 'Rechteck', name: 'Rechteck' },
    { id: 'full-material', name: 'Vollmaterial' },
    { id: 'Vierkantrohr', name: 'Vierkantrohr' },
    { id: 'I-PE', name: 'I-PE' },
    { id: 'HE-A', name: 'HE-A' },
    { id: 'HE-B', name: 'HE-B' },
    { id: 'HE-M', name: 'HE-M' },
    { id: 'U', name: 'U' },
    { id: 'L', name: 'L' },
];
export  const SpecificationCreate = props => {
    return (
        <Create {...props}
                title={<SpecificationTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={requiredValidate}/>
                {/*<TextInput source={"type"} validate={NameValidate}/>*/}
                <SelectInput  source="type" validate={requiredValidate} choices={typeChoices} />
                <NumberInput source={"height"} />
                <NumberInput source={"width"} />
                {/*<NumberInput source={"sideLength"} />*/}
                {/*<NumberInput source={"diameter"} />*/}
                {/*<NumberInput source={"GType"} />*/}
                <NumberInput source={"outerDiameterFrom"} />
                <NumberInput source={"outerDiameterTo"} />
                <NumberInput source={"wallThicknessFrom"} />
                <NumberInput source={"wallThicknessTo"} />
                <NumberInput source={"thicknessFrom"} />
                <NumberInput source={"thicknessTo"} />
            </SimpleForm>
        </Create>
    )
};

export  const SpecificationEdit = props => {
    return (
        <Edit {...props}
                title={<SpecificationTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={requiredValidate}/>
                {/*<TextInput source={"type"} validate={requiredValidate}/>*/}
                <SelectInput  source="type" validate={requiredValidate} choices={typeChoices} />
                <NumberInput source={"height"} />
                <NumberInput source={"width"} />
                {/*<NumberInput source={"sideLength"} />*/}
                {/*<NumberInput source={"diameter"} />*/}
                {/*<NumberInput source={"GType"} />*/}
                <NumberInput source={"outerDiameterFrom"} />
                <NumberInput source={"outerDiameterTo"} />
                <NumberInput source={"wallThicknessFrom"} />
                <NumberInput source={"wallThicknessTo"} />
                <NumberInput source={"thicknessFrom"} />
                <NumberInput source={"thicknessTo"} />
            </SimpleForm>
        </Edit>
    )
};

export const SpecificationShow = props => {
    return(
        <Show {...props} title={<SpecificationTitle/>} >
            <SimpleShowLayout >
                <TextField source={"name"} addLabel={true}/>
                <TextField source={"type"} addLabel={true}/>
                <NumberField source={"height"} addLabel={true}/>
                <NumberField source={"width"} addLabel={true}/>
                {/*<NumberField source={"sideLength"} addLabel={true}/>*/}
                {/*<NumberField source={"diameter"} addLabel={true}/>*/}
                {/*<NumberField source={"GType"} addLabel={true}/>*/}
                <NumberField source={"outerDiameterFrom"} addLabel={true}/>
                <NumberField source={"outerDiameterTo"} addLabel={true}/>
                <NumberField source={"wallThicknessFrom"} addLabel={true}/>
                <NumberField source={"wallThicknessTo"} addLabel={true}/>
                <NumberField source={"thicknessFrom"} addLabel={true}/>
                <NumberField source={"thicknessTo"} addLabel={true}/>
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn placeholder={translate('resources.specifications.fields.name')}/>
            {/*<TextInput source={"type"} />*/}
            <SelectInput  source="type" choices={typeChoices} />
            <NumberInput source={"height"} />
            <NumberInput source={"width"} />
            {/*<NumberInput source={"sideLength"} />*/}
            {/*<NumberInput source={"diameter"} />*/}
            {/*<NumberInput source={"GType"} />*/}
            <NumberInput source={"outerDiameterFrom"} />
            <NumberInput source={"outerDiameterTo"} />
            <NumberInput source={"wallThicknessFrom"} />
            <NumberInput source={"wallThicknessTo"} />
            <NumberInput source={"thicknessFrom"} />
            <NumberInput source={"thicknessTo"} />
        </Filter>
    )
};
export  const SpecificationsList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField source={"name"} addLabel={true}/>
                <TextField source={"type"} addLabel={true}/>
                <NumberField source={"height"} addLabel={true}/>
                <NumberField source={"width"} addLabel={true}/>
                {/*<NumberField source={"sideLength"} addLabel={true}/>*/}
                {/*<NumberField source={"diameter"} addLabel={true}/>*/}
                {/*<NumberField source={"GType"} addLabel={true}/>*/}
                <NumberField source={"outerDiameterFrom"} addLabel={true}/>
                <NumberField source={"outerDiameterTo"} addLabel={true}/>
                <NumberField source={"wallThicknessFrom"} addLabel={true}/>
                <NumberField source={"wallThicknessTo"} addLabel={true}/>
                <NumberField source={"thicknessFrom"} addLabel={true}/>
                <NumberField source={"thicknessTo"} addLabel={true}/>
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
