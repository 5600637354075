import { useState  } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import LockIcon from '@material-ui/icons/Lock';
import { useTranslate,useNotify,useLogin, Notification } from 'react-admin';
import { lightTheme } from './themes';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // background: 'url(https://source.unsplash.com/random/1600x900)',
        background: 'url(background.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
}));

const renderInput = ({
                         meta: { touched, error } = { touched: false, error: undefined },
                         input: { ...inputProps },
                         ...props
                     }) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);


const { Form } = withTypes();

const Login = () => {
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();
    const [forgetPassword, setForgetPassword] = useState(false);
    const queryString = window.location.hash;
    let token ='';
    if(queryString && queryString.split('?').length > 0){
        const urlParams = new URLSearchParams('?'+queryString.split('?')[1]);
        token = urlParams.get('token');
    }
    const handleSubmit = (auth) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            }
        );
    };

    const validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        }
        return errors;
    };
    const  validateEmail = (email)=> {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const handelForgetPassword = (e) =>{
        e.preventDefault();
        const email = document.getElementById("email").value;
        if (!email ) {
            notify(translate('ra.validation.required'),'warning');
        }else if(!validateEmail(email)){
            notify(translate('ra.auth.email_validate'),'warning');
        }
        else{
            setLoading(true);
            const url = process.env.REACT_APP_BACKEND_URL;
            const formData = new FormData();
            formData.append("email",email);

            const request = new Request(`${url}/forget_password`, {
                method: 'POST',
                body: formData,
            });
            fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        notify('ra.auth.not_found_email', 'warning');
                        setLoading(false);
                    }else {
                        notify('pos.forgetPassword.successMsg');
                        setLoading(false);
                        setForgetPassword(false);
                        // return response.json();
                    }
                })
                .catch(error => {
                    notify('ra.auth.not_found_email', 'warning');
                    setLoading(false);
                })
        }

    };

    const handelResetPassword = (e) =>{
        e.preventDefault();
        const password = document.getElementById("password").value;
        const confirm_password = document.getElementById("confirm_password").value;
        if(password.length < 8 ){
            notify(translate('resources.users.errors.password_length'), 'warning');
        }else if(confirm_password < 8){
            notify(translate('resources.users.errors.password_confirm_length'), 'warning');
        }
        else if ((!password && !confirm_password) || password !== confirm_password) {
            notify(translate('resources.users.errors.password_mismatch'), 'warning');
        }else if(!token){
            notify(translate('resources.users.errors.token'), 'warning');
        }else{
            setLoading(true);
            const url = process.env.REACT_APP_BACKEND_URL;
            const formData = new FormData();
            formData.append("plainPassword",confirm_password);
            formData.append("token",token);

            const request = new Request(`${url}/reset_password`, {
                method: 'POST',
                body: formData,
            });
            fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        notify('resources.users.errors.token', 'warning');
                        setLoading(false);
                    }else{
                        notify('pos.resetPassword.successMsg');
                        setLoading(false);
                        window.location.replace('/#/login');
                    }
                })
                .catch(error => {
                    notify('resources.users.errors.token', 'warning');
                    setLoading(false);
                })
        }
    };
    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} noValidate >
                    <div className={classes.main}>
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <div className={classes.hint}>
                                Admin
                            </div>
                            <div className={classes.form}>
                                {!token &&
                                <div className={classes.input}>
                                    <Field
                                        // autoFocus
                                        name="email"
                                        // @ts-ignore
                                        id="email"
                                        component={renderInput}
                                        label={translate('ra.auth.email')}
                                        disabled={loading}
                                    />
                                </div>
                                }
                                {!forgetPassword &&
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        id="password"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('ra.auth.password')}
                                        type="password"
                                        disabled={loading}
                                    />
                                </div>
                                }
                                {token  &&
                                <div className={classes.input}>
                                    <Field
                                        name="confirm_password"
                                        id="confirm_password"
                                        // @ts-ignore
                                        component={renderInput}
                                        label={translate('resources.users.fields.confirm_password')}
                                        type="password"
                                        disabled={loading}
                                    />
                                </div>
                                }
                                {/*{!forgetPassword && !token &&*/}
                                {/*// <div className={classes.input}>*/}
                                {/*//     <Link style={{cursor:'pointer'}} onClick={(e) =>{*/}
                                {/*//         setForgetPassword(true)*/}
                                {/*//     }}>*/}
                                {/*//         {translate('pos.forgetPassword.link')}*/}
                                {/*//     </Link>*/}
                                {/*// </div>*/}
                                {/*}*/}
                            </div>
                            <CardActions className={classes.actions}>
                                {forgetPassword &&
                                <Button
                                    variant="contained"
                                    // type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                    onClick={handelForgetPassword}
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('ra.auth.forget_password')}
                                </Button>
                                }
                                {token &&
                                <Button
                                    variant="contained"
                                    // type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                    onClick={handelResetPassword}
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('ra.auth.reset_password')}
                                </Button>
                                }
                                {!forgetPassword && !token &&
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                >
                                    {loading && (
                                        <CircularProgress
                                            size={25}
                                            thickness={2}
                                        />
                                    )}
                                    {translate('ra.auth.sign_in')}
                                </Button>
                                }
                            </CardActions>
                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props) => (
    <ThemeProvider theme={createMuiTheme(lightTheme)}>
        <Login {...props} />
    </ThemeProvider>
);

export default LoginWithTheme;
