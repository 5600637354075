import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,ReferenceInput,SelectInput,
    ReferenceField,NumberField,NumberInput,BooleanField,BooleanInput
} from 'react-admin';
import React from "react";

const SpeedTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(15)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const OptionTextCustom= ({record}) =>{
    return(
        (['HE-A','HE-B','HE-M','I-PE','L','U'].includes(record.type) ?
            <span>{record.type}{' ['}{record.height}{', '}{record.width}{']'}</span>
                :
                ['Vierkantrohr','Rohr'].includes(record.type) ?
            <span>{record.type}{' (['}{record.outerDiameterFrom}{', '}{record.outerDiameterTo}{'] / ['}{record.wallThicknessFrom}{','}{record.wallThicknessTo}{'])'}</span>
                :
            <span>{record.type}{' ['}{record.thicknessFrom}{', '}{record.thicknessTo}{']'}</span>
        )
    )
}
const ItemPerPage = 99999999;
export  const SpeedCreate = props => {
    const translate = useTranslate();
    return (
        <Create {...props}
                title={<SpeedTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <NumberInput  source={"feed"} validate={requiredValidate}/>
                <NumberInput  source={"speed"} validate={requiredValidate}/>
                <ReferenceInput perPage={ItemPerPage} reference="dimensions" source="dimension"  label={translate('resources.dimensions.fields.name')}>
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="gearings" source="gearing" >
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput  perPage={ItemPerPage} reference="specifications" source="specification" >
                    <SelectInput validate={requiredValidate} optionText={<OptionTextCustom />} />
                    {/*<SelectInput validate={requiredValidate} optionText='name' />*/}
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="products" source="product" label={translate('resources.products.fields.name')} >
                    <SelectInput  optionText='name'/>
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="categories" source="category" >
                    <SelectInput validate={requiredValidate} optionText='name'/>
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="material_groups" source="materialGroup" >
                    <SelectInput validate={requiredValidate} optionText='name'/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    )
};

export  const SpeedEdit = props => {
    const translate = useTranslate();
    return (
        <Edit {...props}
                title={<SpeedTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <NumberInput  source={"feed"} validate={requiredValidate}/>
                <NumberInput  source={"speed"} validate={requiredValidate}/>
                <ReferenceInput perPage={ItemPerPage} reference="dimensions" source="dimension"  label={translate('resources.dimensions.fields.name')}>
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="gearings" source="gearing" >
                    <SelectInput validate={requiredValidate} optionText='name' />
                </ReferenceInput>
                <ReferenceInput  perPage={ItemPerPage} reference="specifications" source="specification" label={translate('resources.specifications.fields.type')}>
                    <SelectInput validate={requiredValidate} optionText={<OptionTextCustom />} />
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="products" source="product" label={translate('resources.products.fields.name')} >
                    <SelectInput  optionText='name'/>
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="categories" source="category" >
                    <SelectInput validate={requiredValidate} optionText='name'/>
                </ReferenceInput>
                <ReferenceInput perPage={ItemPerPage} reference="material_groups" source="materialGroup" >
                    <SelectInput validate={requiredValidate} optionText='name'/>
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    )
};

export const SpeedShow = props => {
    const translate = useTranslate();
    return(
        <Show {...props} title={<SpeedTitle/>} >
            <SimpleShowLayout >
                <NumberField  source={"feed"} />
                <NumberField  source={"speed"} />
                <ReferenceField reference="dimensions" source="dimension"  label={translate('resources.dimensions.fields.name')}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField  reference="gearings" source="gearing" >
                     <TextField source='name' />
                </ReferenceField>
                <ReferenceField   reference="specifications" source="specification" label={translate('resources.specifications.fields.type')}>
                     <TextField source='type' />
                </ReferenceField>
                <ReferenceField  reference="products" source="product" label={translate('resources.products.fields.name')} >
                     <TextField source='name'/>
                </ReferenceField>
                <ReferenceField reference="categories" source="category" >
                     <TextField source='name'/>
                </ReferenceField>
                <ReferenceField reference="material_groups" source="materialGroup" >
                     <TextField source='name'/>
                </ReferenceField>
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="feed" alwaysOn placeholder={translate('resources.speeds.fields.feed')}/>
            <NumberInput  source={"speed"} />
            <ReferenceInput perPage={ItemPerPage} reference="dimensions" source="dimension" >
                <SelectInput optionText='name' />
            </ReferenceInput>
            <ReferenceInput perPage={ItemPerPage} reference="gearings" source="gearing" >
                <SelectInput optionText='name' />
            </ReferenceInput>
            <ReferenceInput  perPage={ItemPerPage} reference="specifications" source="specification" >
                <SelectInput optionText={<OptionTextCustom />} />
            </ReferenceInput>
            <ReferenceInput perPage={ItemPerPage} reference="products" source="product" label={translate('resources.products.fields.name')} >
                <SelectInput optionText='name'/>
            </ReferenceInput>
            <ReferenceInput perPage={ItemPerPage} reference="categories" source="category" >
                <SelectInput optionText='name'/>
            </ReferenceInput>
            <ReferenceInput perPage={ItemPerPage} reference="material_groups" source="materialGroup" >
                <SelectInput optionText='name'/>
            </ReferenceInput>
        </Filter>
    )
};
export  const SpeedsList = props => {
    const translate = useTranslate();
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={true}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <NumberField  source={"feed"} />
                <NumberField  source={"speed"} />
                <ReferenceField reference="dimensions" source="dimension"  label={translate('resources.dimensions.fields.name')}>
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField  reference="gearings" source="gearing" >
                    <TextField source='name' />
                </ReferenceField>
                <ReferenceField   reference="specifications" source="specification" >
                    {/*<TextField source={<OptionTextCustom />} />*/}
                    <TextField source='type' />
                </ReferenceField>
                <ReferenceField  reference="products" source="product" label={translate('resources.products.fields.name')} >
                    <TextField source='name'/>
                </ReferenceField>
                <ReferenceField reference="categories" source="category" >
                    <TextField source='name'/>
                </ReferenceField>
                <ReferenceField reference="material_groups" source="materialGroup" >
                    <TextField source='name'/>
                </ReferenceField>

                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
