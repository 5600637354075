import {forwardRef, useState} from 'react';
import {AppBar, UserMenu, MenuItemLink, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';
import ProfileIcon from '@material-ui/icons/People';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import Logo from './Logo';

const useStyles = makeStyles(theme => ({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    root: {
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        fontSize: '0.7em'
    },
    avatar: {
        marginRight: theme.spacing(1),
        height: 30,
        width: 30,
    },
}));


const ConfigurationMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={translate('pos.configuration')}
            leftIcon={<SettingsIcon />}
            onClick={props.onClick}
        />
    );
});
const ProfileMenu = forwardRef((props, ref) => {
    const translate = useTranslate();
    const user = JSON.parse(localStorage.getItem('user'));
    return (
        <MenuItemLink
            ref={ref}
            to={`/${user.role}/%2Fapi%2F${user.role}%2F${user.id}/show`}
            // to={`/users/%2Fapi%2Fusers%2F1/show`}
            primaryText={translate('pos.profile')}
            leftIcon={<ProfileIcon />}
            onClick={props.onClick}
        />
    );
});

const MyCustomIcon = () => {
    const classes = useStyles();
    const data = localStorage.getItem('user');
    const initial = data ? JSON.parse(data) : {};
    const [user, setUser] = useState( initial);
    return (
        <div className={classes.root}>
            <Avatar
                className={classes.avatar}
                src="user.jpg"
            />
            {user.name}
        </div>
    )
};

const CustomUserMenu = (props) => {
    return (
        <UserMenu {...props} icon={<MyCustomIcon />} >
            <ProfileMenu />
            <ConfigurationMenu />
        </UserMenu>
    )
};

const CustomAppBar = (props) => {
    const classes = useStyles();

    return (
        <AppBar {...props} userMenu={<CustomUserMenu />} >
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <Logo />
            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
