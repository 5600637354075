import pure from "recompose/pure";

const AddressField = ({ record }) =>
    record.street || record.zip || record.city ? (
        <span>
            {record.street}, {record.zip} {record.city}
        </span>
    ) : null;

const PureAddressField = pure(AddressField);

PureAddressField.defaultProps = {
    source: 'street',
    label: 'resources.users.fields.address',
};
export default PureAddressField;
