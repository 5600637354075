import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    TextInput, SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField,  Datagrid, useTranslate,Show,RichTextField,
    FunctionField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

const EmailTemplateTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(100)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export  const EmailTemplateCreate = props => {
    const translate = useTranslate();
    return (
        <Create {...props}
                title={<EmailTemplateTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={NameValidate}/>
                <TextInput source={"subject"} validate={NameValidate}/>
                <FunctionField label={translate('resources.email_templates.fields.vars')} render={record => {
                    return record.vars.map((r)=>"{{"+r+"}}, ");
                }} />
                <RichTextInput source="description" />
            </SimpleForm>
        </Create>
    )
};

export  const EmailTemplateEdit = props => {
    const translate = useTranslate();
    return (
        <Edit {...props}
                title={<EmailTemplateTitle/>}
                hasShow={false}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <TextInput source={"name"} validate={NameValidate}/>
                <TextInput source={"subject"} validate={NameValidate}/>
                <FunctionField label={translate('resources.email_templates.fields.vars')} render={record => {
                    return record.vars.map((r)=>"{{"+r+"}}, ");
                }

                } />
                <RichTextInput source="description" />
            </SimpleForm>
        </Edit>
    )
};

export const EmailTemplateShow = props => {
    return(
        <Show {...props} title={<EmailTemplateTitle/>} >
            <SimpleShowLayout >
                <TextField source={"name"} addLabel={true} />
                <TextField source={"subject"} addLabel={true} />
                <RichTextField source="description" />
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="name" alwaysOn placeholder={translate('resources.email_templates.fields.name')}/>
            {/*<RichTextInput source="description" />*/}
            <TextInput source={"description"} />
            <TextInput source={"subject"} />
        </Filter>
    )
};
export  const EmailTemplatesList = props => {
    return(
        <List {...props}
                     hasEdit={true}
                     hasShow={false}
                     hasCreate={false}
                     exporter={false}
                     filters={<SearchFilter />}
                     sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <TextField source={"name"}  />
                <TextField source={"subject"}  />
                <RichTextField source="description" />
                <EditButton/>
                {/*<ShowButton/>*/}
            </Datagrid>
        </List>
    )};
