import React from 'react';
import {
    DateInput,
    Edit,
    BooleanInput,
    TextInput,
    PasswordInput,
    Toolbar,
    useTranslate,
    FormWithRedirect,
    SelectInput, usePermissions, SaveButton, SimpleForm,
    required,
    RadioButtonGroupInput, email,
    ReferenceArrayInput,
    SelectArrayInput, regex, minLength, maxLength, ReferenceInput, useNotify
} from 'react-admin';

import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import FullNameField from './FullNameField';

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const validatePasswords = ({plainPassword,confirm_password}) => {
    const errors = {};
    if (plainPassword && confirm_password && plainPassword !== confirm_password) {
        errors.confirm_password = [
            'resources.users.errors.password_mismatch',
        ];
    }

    return errors;
};


const UserTitle = ({ record }) => record ? <FullNameField record={record} size="32" /> : null;
const UserEdit = (props) => {
    const translate = useTranslate();

    const requiredValidate = [required()];
    const validateEmail = [required(),email()];
    const validateZipCode = [required(),minLength(2),maxLength(15),regex(/^\d+$/, translate('resources.users.errors.zip'))];
    const validateFirstName = [required(), minLength(2), maxLength(100)];
    const validateCity = [required(), minLength(2), maxLength(15)];
    const validateStreet = [required(), minLength(2), maxLength(30)];
    const validatePassword = [required(), minLength(8)];
// const ValidatePhone = [required(),regex(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,'Muss eine gültige Telefonnummer sein')];
    const ValidatePhone = [required(),regex(/\(?\+\(?49\)?[ ()]?([- ()]?\d[- ()]?){10}/im,translate('resources.users.errors.phone'))];

    const { permissions } = usePermissions();
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const currentUserId = user.id;
    const role = user.role;
    const id = props.id.split("/")[3];
    // const myPermissions = useSelector((state) => state.permissions);
    const notify = useNotify();
    return (
        <Edit
            {...props}
            title={<UserTitle />}
            onFailure={(error) => notify( 'E-Mail ist bereits vergeben', 'warning')}
        >
            <SimpleForm toolbar={<EditActions />} validate={validatePasswords}
                        redirect='show'
            >
                        <Box style={{width:'100%'}} display={{ md: 'block', lg: 'flex' }}>
                            <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                                <Typography variant="h6" gutterBottom>
                                    {translate(
                                        'resources.users.fieldGroups.identity'
                                    )}
                                </Typography>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box
                                        flex={1}
                                        mr={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <TextInput
                                            source="firstName"
                                            resource="users"
                                            validate={validateFirstName}
                                            fullWidth
                                        />
                                    </Box>
                                    <Box
                                        flex={1}
                                        ml={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <TextInput
                                            source="lastName"
                                            resource="users"
                                            validate={validateFirstName}
                                            fullWidth
                                        />
                                    </Box>
                                </Box>
                                <TextInput
                                    type="email"
                                    source="email"
                                    resource="users"
                                    validate={validateEmail}
                                    fullWidth
                                />
                                {/*<Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box
                                        flex={1}
                                        mr={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <DateInput
                                            source="birthday"
                                            validate={requiredValidate}
                                            resource="users"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box
                                        flex={1}
                                        ml={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <SelectInput source="gender" resource="users" fullWidth
                                                     validate={requiredValidate}
                                                     choices={[
                                            { id: 0, name: 'resources.users.fields.genders.male'},
                                            { id: 1, name: 'resources.users.fields.genders.female' },
                                            { id: 2, name: 'resources.users.fields.genders.other' },
                                        ]} />
                                    </Box>
                                </Box>
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <TextInput type="tel" source="phone" validate={ValidatePhone} placeholder="z.B +49123456789" resource="users" fullWidth/>
                                </Box>*/}
                                {/*<Box mt="1em" />

                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.users.fieldGroups.address')}
                                </Typography>
                                <TextInput
                                    validate={validateStreet}
                                    source="street"
                                    resource="users"
                                    fullWidth
                                />
                                <Box display={{ xs: 'block', sm: 'flex' }}>
                                    <Box
                                        flex={1}
                                        mr={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <TextInput
                                            validate={validateZipCode}
                                            source="zip"
                                            resource="users"
                                            fullWidth
                                        />
                                    </Box>
                                    <Box
                                        flex={2}
                                        ml={{ xs: 0, sm: '0.5em' }}
                                    >
                                        <TextInput
                                            validate={validateCity}
                                            source="city"
                                            resource="users"
                                            fullWidth
                                        />
                                    </Box>
                                </Box>

                                <Box mt="1em" />*/}

                                { id == currentUserId &&
                                <Box>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fieldGroups.change_password'
                                        )}
                                    </Typography>

                                    <Box display={{xs: 'block', sm: 'flex'}}>
                                        <Box
                                            flex={1}
                                            mr={{xs: 0, sm: '0.5em'}}
                                        >
                                            <PasswordInput
                                                source="plainPassword"
                                                resource="users"
                                                autoComplete="new-password"
                                                validate={validatePassword}
                                                fullWidth
                                            />
                                        </Box>
                                        <Box
                                            flex={1}
                                            ml={{xs: 0, sm: '0.5em'}}
                                        >
                                            <PasswordInput
                                                source="confirm_password"
                                                validate={validatePassword}
                                                resource="users"
                                                fullWidth
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                }
                            </Box>
                            <Box
                                flex={1}
                                ml={{ xs: 0, lg: '1em' }}
                                mt={{ xs: '1em', lg: 0 }}
                            >
                                { permissions === 'customers' && (
                                    <div>
                                        <Typography variant="h6" gutterBottom>
                                            {translate(
                                                'resources.customers.fields.company'
                                            )}
                                        </Typography>
                                        <div>
                                            <ReferenceInput
                                                source="company"
                                                reference="companies"
                                                label={translate('resources.customers.fields.company')}
                                            >
                                                <SelectInput  validate={requiredValidate} optionText="name" />
                                            </ReferenceInput>
                                        </div>

                                    </div>
                                )}
                                <div>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fields.config'
                                        )}
                                    </Typography>
                                    <div>
                                        <BooleanInput
                                            defaultValue={true}
                                            source="masterCalc"
                                            resource="users"
                                        />
                                    </div>
                                    <div>
                                        <BooleanInput
                                            defaultValue={true}
                                            source="konsignationsLager"
                                            resource="users"
                                        />
                                    </div>
                                    <Typography variant="h6" gutterBottom>
                                        {translate(
                                            'resources.users.fields.status'
                                        )}
                                    </Typography>
                                    <div>
                                        <BooleanInput
                                            defaultValue={true}
                                            source="active"
                                            resource="users"
                                        />
                                    </div>
                                </div>
                            </Box>
                        </Box>
            </SimpleForm>
        </Edit>
    );
};





export default UserEdit;
