import {
    DateField,
    TextField,
    useTranslate,
    SimpleShowLayout,
    FunctionField,
    Show,
    BooleanField,
    EmailField,
    Tab,
    TabbedShowLayout,
    EditButton,
    useShowController
} from 'react-admin';

import FullNameField from './FullNameField';
const UserTitle = ({ record }) => record ? <FullNameField record={record} size="32" /> : null;

const ShowTimeOf = ({record})=>{
    let date = new Date(record.timeOf);
    date.setHours(date.getHours() - 1);
    const timeOfValue = date.toLocaleTimeString();
    return <span>{timeOfValue}</span>
};
const ShowTimeTo = ({record})=>{
    let date = new Date(record.timeTo);
    date.setHours(date.getHours() - 1);
    const timeToValue = date.toLocaleTimeString();
    return <span>{timeToValue}</span>
};

const UserShow = props => {
    const translate = useTranslate();
    const { record } = useShowController(props);
    const url = window.location.hash.split('/')[1];
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    const role = (user) ? user.role : null;
    return(
        <Show{...props} title={<UserTitle/>}
             hasEdit={false}
        >
            <SimpleShowLayout>
                <TabbedShowLayout>
                    <Tab  label="resources.customers.tabs.infoPersonnel">
                        <EmailField source="email" type="email" />
                        {/*<TextField source="phone" type="phone" />
                        <DateField source="birthday" type="date" />
                        <FunctionField  resource="users"  label='resources.users.fields.gender' render={(record)=> {
                            if(record.gender === 0)
                                return translate('resources.users.fields.genders.male');
                            if(record.gender === 1)
                                return translate('resources.users.fields.genders.female');
                            if(record.gender === 2)
                                return  translate('resources.users.fields.genders.other');
                        }}
                        />
                        <FunctionField  resource="users"  label='resources.users.fields.address' render={(record)=> {
                                return record && record.street+", "+record.zip+" "+record.city;
                        }}
                        />*/}
                        <BooleanField source="active" label="Active" />
                        <BooleanField source="masterCalc"  />
                        <BooleanField source="konsignationsLager" />
                        <br/>
                        <br/>
                        {['admins'].includes(role) &&
                            <EditButton variant="contained" color="primary"/>
                        }
                    </Tab>
                </TabbedShowLayout>
            </SimpleShowLayout>
        </Show>
    );
};
export default UserShow;
