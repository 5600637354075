import  {Fragment} from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    Filter,
    List,
    BooleanInput,
    SearchInput, TextInput,
    EmailField,
    FunctionField,
    TextField,
    downloadCSV,
    useTranslate,
    ShowButton,
    EditButton, ReferenceField
} from 'react-admin';
import { useMediaQuery, makeStyles } from '@material-ui/core';

import  AddressField from './AddressField'
import MobileGrid from './MobileGrid';
import FullNameField from "./FullNameField";
import jsonExport from 'jsonexport/dist';
import DeleteButtonWithConfirmation from "../DeleteButtonWithConfirmation";
import {replaceUmlaute} from "../utils/Umlaut";
import Moment from "moment";
import {useSelector} from "react-redux";
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
const exporter = (users) => {
    const data = users.map(obj => ({
        firstName: replaceUmlaute(obj.firstName),
        lastName: replaceUmlaute(obj.lastName),
        email: obj.email,
        phone: obj.phone,
        birthday: Moment(obj.birthday).format('DD.MM.YYYY'),
        street: replaceUmlaute(obj.street),
        zip: obj.zip,
        city: replaceUmlaute(obj.city),
        active: obj.active ? 1 : 0,
    }));
    jsonExport(data,{
            rowDelimiter: ';'
        },(err, csv) => {
        downloadCSV(csv, 'users');
    });
};

const SearchFilter = props => {
    const translate = useTranslate();
    return(
    <Filter {...props} >
        <SearchInput source="lastName" alwaysOn placeholder={translate('resources.users.fields.lastName')}/>
        <TextInput source="firstName" />
        <TextInput source="email" />
        <TextInput source="phone" />
        <TextInput source="street" />
        <TextInput source="zip" />
        <TextInput source="city" />
        <BooleanInput source="active" defaultValue />
    </Filter>
)};


const useStyles = makeStyles({
    nb_commands: { color: 'purple' },
});

const PostBulkActionButtons = props => (
    <Fragment>
        <DeleteButtonWithConfirmation {...props} />
    </Fragment>
);
const  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1,-1);
};
const UserList = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const isXsmall = useMediaQuery(theme =>
        theme.breakpoints.down('xs')
    );
    // const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    // const role = (user) ? user.role : null;
    // const module = capitalizeFirstLetter(props.resource);
    // const permissions = useSelector((state) => state.permissions);
    return (
        <List
            {...props}
            filters={<SearchFilter />}
            exporter={false}
            bulkActionButtons={<PostBulkActionButtons />}
            // hasCreate={['admins'].includes(role)}
            // hasEdit={['admins'].includes(role)}
            // hasShow={['admins','teachers'].includes(role)}
        >
            {isXsmall ? (
                <MobileGrid />
            ) : (
                <Datagrid >
                    <FullNameField {...props} />
                    <EmailField source="email" type="email" />
                    {/*<TextField source="phone" type="phone" />
                    <DateField source="birthday" type="date" />
                    <FunctionField  resource="users"  label='resources.users.fields.gender' render={(record)=> {
                            if(record.gender === 0)
                                return translate('resources.users.fields.genders.male');
                            if(record.gender === 1)
                                return translate('resources.users.fields.genders.female');
                            if(record.gender === 2)
                                return  translate('resources.users.fields.genders.other');
                        }}
                    />
                    <AddressField {...props} />*/}
                    <BooleanField source="active" label="Active" />
                    <BooleanField source="masterCalc" label="MasterCalc" />
                    <BooleanField source="konsignationsLager" label="konsignationslager" />
                    <BooleanField source="active" label="Active" />
                    { props.resource === 'customers' && (
                        <ReferenceField reference="companies" source="company" link={false}>
                            <TextField source="name" />
                        </ReferenceField>
                    )}
                    <CustomEditButton/>
                    <CustomShowButton/>
                </Datagrid>
            )}
        </List>
    );
};

const CustomEditButton = ({record,resource})=>{
    // const moduleName = record.roles[record.roles.length -1].split('_')[1].toLowerCase()+'s';
    // const moduleName = (record.roles[0] === "ROLE_CUSTOMER") ? 'customers' :'teachers';
    const href = '/#/'+resource+'/%2Fapi%2F'+resource+'%2F'+record.originId;
    const translate = useTranslate();
    return (
        <Button startIcon={<EditIcon />}  color="primary" href={href} >{translate('ra.action.edit')}</Button>
    )
}
const CustomShowButton = ({record, resource})=>{
    // const moduleName = (record.roles[0] === "ROLE_CUSTOMER") ? 'customers' :'teachers';
    // const moduleName = record.roles[record.roles.length -1].split('_')[1].toLowerCase()+'s';
    const href = '/#/'+resource+'/%2Fapi%2F'+resource+'%2F'+record.originId+'/show';
    const translate = useTranslate();
    return (
        <Button startIcon={<VisibilityIcon />}  color="primary" href={href} >{translate('ra.action.show')}</Button>
    )
}


export default UserList;
