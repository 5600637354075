import {
    ShowButton,
    EditButton,
    Filter,
    SearchInput,
    SimpleShowLayout,
    SimpleForm,
    Edit,
    Toolbar,
    SaveButton,
    required, minLength, maxLength, List,
    Create, TextField, Datagrid, useTranslate, Show, ReferenceInput, SelectInput,
    ReferenceField, RichTextField, NumberInput,TextInput
} from 'react-admin';
import RichTextInput from "ra-input-rich-text";

const NotificationTitle = ({ record }) => <span>{record.name}</span>;
const NameValidate = [required(), minLength(2), maxLength(100)];
const requiredValidate = [required()];

const EditActions = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export  const NotificationCreate = props => {
    return (
        <Create {...props}
                title={<NotificationTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <ReferenceInput reference="admins"
                                source="user">
                    <SelectInput validate={requiredValidate} optionText='firstName' />
                </ReferenceInput>
                <TextInput source="subject" validate={NameValidate}/>
                <RichTextInput source="message" validate={NameValidate}/>
            </SimpleForm>
        </Create>
    )
};

export  const NotificationEdit = props => {
    return (
        <Edit {...props}
              title={<NotificationTitle/>}
        >
            <SimpleForm toolbar={<EditActions />}
                        redirect={'list'}
            >
                <ReferenceInput reference="admins"
                                source="user">
                    <SelectInput validate={requiredValidate} optionText='firstName' />
                </ReferenceInput>
                <TextInput source="subject" validate={NameValidate}/>
                <RichTextInput source="message" validate={NameValidate}/>
            </SimpleForm>
        </Edit>
    )
};

export const NotificationShow = props => {
    return(
        <Show {...props} title={<NotificationTitle/>} >
            <SimpleShowLayout >
                <ReferenceField reference="admins" source="user" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source={"subject"} addLabel={true} />
                <RichTextField source={"message"} addLabel={true} />
            </SimpleShowLayout>
        </Show>
    )
};


const SearchFilter = props => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <SearchInput source="subject" alwaysOn placeholder={translate('resources.notifications.fields.subject')}/>
            {/*<RichTextInput source="message"/>*/}
        </Filter>
    )
};
export  const NotificationsList = props => {
    return(
        <List {...props}
              hasEdit={true}
              hasShow={true}
              exporter={false}
              filters={<SearchFilter />}
              sort={{ field: 'id', order: 'DESC' }}
        >
            <Datagrid>
                <ReferenceField reference="admins" source="user" link={false}>
                    <TextField source="firstName" />
                </ReferenceField>
                <TextField source={"subject"} />
                <RichTextField  source={"message"} />
                <EditButton/>
                <ShowButton/>
            </Datagrid>
        </List>
    )};
