import  { Fragment, useState } from 'react';
import {
    Button,
    Confirm,
    useDeleteMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    useTranslate,
} from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
const DeleteButtonWithConfirmation = ({ selectedIds,resource }) => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    const translate = useTranslate();
    const [deleteMany, { loading }] = useDeleteMany(
        resource,
        selectedIds,
        {
            onSuccess: () => {
                refresh();
                // notify('deleted');
                unselectAll(resource);
                if(resource === "courses" || resource === "your_courses")
                    window.location.reload();


            },
            onFailure: error =>{
                if(resource === "backend_groups")
                    notify('resources.backend_groups.errors.'+error.message, 'warning');
                else
                    notify('Error: '+resource+' not deleted', 'warning');
            }
        }
    );
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        deleteMany();
        setOpen(false);
    };

    return (
        <Fragment>
            <Button label={translate("pos.delete.name")} onClick={handleClick} style={{color:'#f44336',fontWeight:'bold'}} >
                <DeleteIcon />
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={translate("pos.delete.name")}
                content={translate("pos.delete.msg")}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}


export default DeleteButtonWithConfirmation;